/* eslint-disable no-param-reassign */
import produce from 'immer';
var samplesValueInMetadataTrackUpdated = produce(function (draft, action) {
  var _action$payload = action.payload,
    sampleUuids = _action$payload.sampleUuids,
    key = _action$payload.key,
    value = _action$payload.value;
  sampleUuids.forEach(function (sampleUuid) {
    draft[sampleUuid].metadata[key] = value;
  });
  draft.meta.saving = false;
  draft.meta.error = false;
});
export default samplesValueInMetadataTrackUpdated;