import _isArray from "lodash/isArray";
import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.array.find.js";
import createMemoizedSelector from 'redux/selectors/createMemoizedSelector';
import getCellSetsHierarchy from './getCellSetsHierarchy';
var getCellSetsHierarchyByKeys = function getCellSetsHierarchyByKeys(keys) {
  return function (hierarchy) {
    if (hierarchy.length === 0 || !_isArray(keys)) return [];
    return keys.map(function (key) {
      return hierarchy.find(function (child) {
        return child.key === key;
      });
    });
  };
};
export default createMemoizedSelector(getCellSetsHierarchyByKeys, {
  inputSelectors: getCellSetsHierarchy()
});